import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import materialMovementService from "../js/material-movement-service";
import Utility from "../../../shared/utility";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      disableTransferEntireLPN: false,
      disableTransferSelectUnits: false,
      disableDropOffLPNToStage: false,
      disableTransferUnitToStaging: false,
      showViewReset: false,
      lpnUsed: "",
      inTransitLPN: "",
      lpnDestination: "",
      showTransferEntireLPN: false,
      showTransferSelectUnits: false,
      showDropOffLPNToStage: false,
      showTransferUnitToStage: false,
      showDivider: false,
      lpnDetailsDialog: false,
      partBCNESN: "",
      disablePartBCNESN: false,
      destBinLPN: "",
      disableDestBinLPN: true,
      destinationLPN: "",
      disableDestinationLPN: true,
      quantity: "",
      disableQuantity: true,
      disableDropOffSubmit: true,
      disableTransferUnitSubmit: true,
      stagingLocation: "",
      disableStagingLocation: false,
      transferUnitStagingLocation: "",
      disableTransferUnitStagingLocation: false,
      lpnInStagingLoc: "",
      disableLpnInStagingLoc: true,
      stagingLocPartBCNESN: "",
      disableStagingLocPartBCNESN: true,
      stagingLocQuantity: "",
      disableStagingLocQuantity: true,
      disableTransferUnitToStagingSubmit: true,
      availableQty: 0,
      lpnDetailsItems: [],
      lpnText: "",
      lpnNum: "",
      transferredItems: [],
      showTransferred: false,
      availDetItems: [],
      disableTransEntireSubmit: true,
      disableLPNDestination: false,
      paramTransferred: 0,
      showDestBinLPN: false,
      showDestinationLPN: false,
      showQuantity: false,
      showTransferUnitSubmit: false,
      lpnDetailsHeaders: [
        { text: "Part Number", align: "start", value: "PartNumber" },
        { text: "Warehouse", value: "Warehouse" },
        { text: "Quantity", value: "Quantity" },
      ],
      transferredHeaders: [
        { text: "Part Number", align: "start", value: "PartNumber" },
        { text: "Bin", value: "Bin" },
        { text: "LPN", value: "LPN" },
        { text: "Quantity", value: "Quantity" },
      ],
      availDetHeaders: [
        { text: "Warehouse", align: "start", value: "warehouse", class: "primary customwhite--text" },
        { text: "Location", value: "location", class: "primary customwhite--text" },
        { text: "Suggested Bins", value: "bin", class: "primary customwhite--text" },
        { text: "Available Slots", value: "available", class: "primary customwhite--text" },
      ],
      lpnRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
      ],
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Value must be 50 characters or less"],
      max50Alphanumeric: [
        (v) => !!v || "Field is required",
        (v) => !v || /^[A-Z0-9-]*$/i.test(v) || "Only Alphanumberic allowed",
        (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less",
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getBreadCrumbLPN();
    this.onPageLoadGetButtons();
  },
  computed: {
    processedData() {
      const data = this.availDetItems;
      if (!data.length) return [];
      const processed = [];
      let currentWarehouse = null;
      let currentLocation = null;
      let currentRowspan = 0;
      data.forEach((row) => {
        if (row.warehouse !== currentWarehouse || row.location !== currentLocation) {
          currentWarehouse = row.warehouse;
          currentLocation = row.location;
          currentRowspan = data.filter((d) => d.warehouse == currentWarehouse && d.location == currentLocation).length;
          row.rowspan = currentRowspan;
          row.skipWarehouse = true;
        } else {
          row.skipWarehouse = false;
        }
        processed.push(row);
      });
      return processed;
    },
  },
  methods: {
    //Breadcrumb Data
    async getBreadCrumbLPN() {
      let lpnStatus = await AddFavourite.getLPNDetails(this.userId);
      this.lpnText = lpnStatus[0]?.lpn_info;
      this.lpnNum = lpnStatus[0]?.lpn;
    },
    //Reset Function
    resetFunction() {
      this.disableTransferEntireLPN = false;
      this.disableTransferSelectUnits = false;
      this.disableDropOffLPNToStage = false;
      this.disableTransferUnitToStaging = false;
      this.showViewReset = false;
      this.lpnUsed = "";
      this.inTransitLPN = "";
      this.lpnDestination = "";
      this.showTransferEntireLPN = false;
      this.showTransferSelectUnits = false;
      this.showDropOffLPNToStage = false;
      this.showTransferUnitToStage = false;
      this.showDivider = false;
      this.lpnDetailsDialog = false;
      this.partBCNESN = "";
      this.disablePartBCNESN = false;
      this.destBinLPN = "";
      this.disableDestBinLPN = true;
      this.destinationLPN = "";
      this.disableDestinationLPN = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableDropOffSubmit = true;
      this.stagingLocation = "";
      this.disableStagingLocation = false;
      this.disableTransferUnitSubmit = false;
      this.transferUnitStagingLocation = "";
      this.disableTransferUnitStagingLocation = false;
      this.lpnInStagingLoc = "";
      this.disableLpnInStagingLoc = true;
      this.stagingLocPartBCNESN = "";
      this.disableStagingLocPartBCNESN = true;
      this.stagingLocQuantity = "";
      this.disableStagingLocQuantity = true;
      this.disableTransferUnitToStagingSubmit = true;
      this.lpnDetailsItems = [];
      this.transferredItems = [];
      this.availDetItems = [];
      this.disableTransEntireSubmit = true;
      this.disableLPNDestination = false;
      this.paramTransferred = 0;
      this.showTransferred = false;
      this.showDestBinLPN = false;
      this.showDestinationLPN = false;
      this.showQuantity = false;
      this.showTransferUnitSubmit = false;
      this.onPageLoadGetButtons();
    },
    //On Page load get button values
    async onPageLoadGetButtons() {
      let pageData = await materialMovementService.putAwayLPNSortInitial("get", this.userId, "0");
      if (pageData !== null && pageData !== undefined) {
        if (pageData.message !== "NA") {
          this.disableTransferEntireLPN = !pageData[0].enable_entire;
          this.disableTransferSelectUnits = !pageData[0].enable_select;
          this.lpnUsed = pageData[0].lpn;
          this.$refs.refUnitID.focus();
          this.getBreadCrumbLPN();
        }
      }
    },
    //Transfer Entire LPN button click
    async transferEntireLPN() {
      this.inTransitLPN = this.lpnUsed;
      let obj = {
        user_id: parseInt(this.userId),
        lpn: this.lpnUsed,
        method: 1,
      };
      let showMessage = false;
      let data = await materialMovementService.transferEntireLPN("post", obj, showMessage);
      if (data !== null && data !== undefined) {
        if (data.message !== "NA") {
          this.formsReset();
          this.showTransferEntireLPN = true;
          this.showTransferSelectUnits = false;
          this.showDropOffLPNToStage = false;
          this.showTransferUnitToStage = false;
          this.showDivider = true;
          this.showViewReset = true;
          this.$refs.transferEntireLPNForm.resetValidation();
        }
      }
    },
    //Validate LPN Destination
    async putAwayLPNValidateDest() {
      this.inTransitLPN = this.lpnUsed;
      let obj = {
        user_id: parseInt(this.userId),
        it_lpn: this.lpnUsed,
        bin: this.lpnDestination,
      };
      let showMessage = false;
      let data = await materialMovementService.putAwayLPNValidateDest("post", obj, showMessage);
      if (data !== null && data !== undefined) {
        if (data.message !== "NA") {
          this.disableLPNDestination = true;
          this.disableTransEntireSubmit = false;
        }
      }
    },
    //Submit in Transfer Entire LPN
    submitTransferEntireLPN() {
      if (this.$refs.transferEntireLPNForm.validate()) {
        this.inTransitLPN = this.lpnUsed;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let obj = {
          user_id: parseInt(this.userId),
          orig_lpn: this.lpnUsed,
          bin: this.lpnDestination,
        };
        this.axios
          .post("mm/putaway_transfer_whole", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.formsReset();
              this.onPageLoadGetButtons();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Transfer Select Units
    async transferSelectUnits() {
      this.inTransitLPN = this.lpnUsed;
      let obj = {
        user_id: parseInt(this.userId),
        lpn: this.lpnUsed,
        method: 2,
      };
      let showMessage = false;
      let data = await materialMovementService.transferSelectUnits("post", obj, showMessage);
      if (data !== null && data !== undefined) {
        if (data.message !== "NA") {
          this.showTransferEntireLPN = false;
          this.showTransferSelectUnits = true;
          this.showDropOffLPNToStage = false;
          this.showTransferUnitToStage = false;
          this.showDivider = true;
          this.showViewReset = true;
        }
      }
    },
    //Validate Unit ID
    async validatePartBCNESN() {
      this.inTransitLPN = this.lpnUsed;
      let obj = {
        user_id: parseInt(this.userId),
        pass: 1,
        it_lpn: this.lpnUsed,
        bcn: this.partBCNESN,
        bin: "",
        lpn: "",
        qty: ""
      };
      let showMessage = false;
      let data = await materialMovementService.fieldsValidTransSelUnits("post", obj, showMessage);
      if (data !== null && data !== undefined) {
        if (data.message !== "NA") {
          this.availDetItems = data?.Result;
          if (this.availDetItems !== "" && this.availDetItems !== undefined) {
            this.availDetItems.forEach((ele) => {
              ele.skipWarehouse = false;
              ele.rowspan = 0;
            });
          }
          this.disableDestBinLPN = !data?.Result2[0]?.enable_bin;
          this.disableDestinationLPN = !data?.Result2[0]?.enable_lpn;
          this.disableQuantity = !data?.Result2[0]?.enable_qty;
          this.disableTransferUnitSubmit = !data?.Result2[0]?.enable_submit;
          this.disablePartBCNESN = true;
          this.availableQty = data?.Result2[0]?.avail_qty;
          this.showDestBinLPN = true;
          this.showTransferUnitSubmit = data?.Result2[0]?.enable_submit;
          if (data?.Result2[0].enable_bin) {
            this.showDestBinLPN = true;
          }
          if (data?.Result2[0].enable_lpn) {
            this.showDestinationLPN = true;
          }
          if (data?.Result2[0].enable_qty) {
            this.showQuantity = true;
          }
          if (!this.disableTransferUnitSubmit) {
            this.quantity = 1;
          }
        }
      }
    },
    //Validate Destination Bin or LPN
    async validateDestBinLPN() {
      this.inTransitLPN = this.lpnUsed;
      let obj = {
        user_id: parseInt(this.userId),
        pass: 2,
        it_lpn: this.lpnUsed,
        bcn: this.partBCNESN,
        bin: this.destBinLPN,
        lpn: "",
        qty: ""
      };
      let showMessage = false;
      let data = await materialMovementService.fieldsValidTransSelUnits("post", obj, showMessage);
      if (data !== null && data !== undefined) {
        if (data.message !== "NA") {
          this.disableDestBinLPN = true;
          this.disableDestinationLPN = !data[0].enable_lpn;
          this.disableQuantity = !data[0].enable_qty;
          this.disableTransferUnitSubmit = !data[0].enable_submit;
          this.availableQty = data[0].avail_qty;
          this.showDestinationLPN = true;
          this.showTransferUnitSubmit = data[0]?.enable_submit;
          if (data[0].enable_lpn) {
            this.showDestinationLPN = true;
          }
          if (data[0].enable_qty) {
            this.showQuantity = true;
          }
          if (this.showDestinationLPN) {
            this.$refs.destinationLPNForm.resetValidation();
          }
          if (!this.disableTransferUnitSubmit) {
            this.quantity = 1;
          }
        }
      }
    },
    //Get next available LPN number
    async getLPNNumber() {
      let lpnData = await commonAPIService.getNextAvailableLPN("get", this.userId);
      if (lpnData !== null && lpnData !== undefined) {
        if (lpnData.message !== "NA") {
          this.destinationLPN = lpnData[0].lpn;
          let obj = {
            user_id: parseInt(this.userId),
            pass: 3,
            it_lpn: this.lpnUsed,
            bcn: this.partBCNESN,
            bin: this.destBinLPN,
            lpn: this.destinationLPN,
            qty: ""
          };
          let showMessage = false;
          let data = await materialMovementService.fieldsValidTransSelUnits("post", obj, showMessage);
          if (data !== null && data !== undefined) {
            if (data.message !== "NA") {
              this.disableDestinationLPN = true;
              this.disableQuantity = !data[0].enable_qty;
              this.disableTransferUnitSubmit = !data[0].enable_submit;
              this.showTransferUnitSubmit = data[0]?.enable_submit;
              this.availableQty = data[0].avail_qty;
              this.showQuantity = true;
              if (!this.disableTransferUnitSubmit) {
                this.quantity = 1;
              }
            }
          }
        }
      }
    },
    //Validate Destination Bin or LPN
    async validateDestinationLPN() {
      if (this.$refs.destinationLPNForm.validate()) {
        this.inTransitLPN = this.lpnUsed;
        let obj = {
          user_id: parseInt(this.userId),
          pass: 3,
          it_lpn: this.lpnUsed,
          bcn: this.partBCNESN,
          bin: this.destBinLPN,
          lpn: this.destinationLPN,
          qty: ""
        };
        let showMessage = false;
        let data = await materialMovementService.fieldsValidTransSelUnits("post", obj, showMessage);
        if (data !== null && data !== undefined) {
          if (data.message !== "NA") {
            this.disableDestinationLPN = true;
            this.disableQuantity = !data[0].enable_qty;
            this.disableTransferUnitSubmit = !data[0].enable_submit;
            this.availableQty = data[0].avail_qty;
            this.showQuantity = true;
            this.showTransferUnitSubmit = data[0]?.enable_submit;
            if (!this.disableTransferUnitSubmit) {
              this.quantity = 1;
            }
          }
        }
      }
    },
    //Validate Destination Bin or LPN
    async validateQuantity() {
      this.inTransitLPN = this.lpnUsed;
      let obj = {
        user_id: parseInt(this.userId),
        pass: 4,
        it_lpn: this.lpnUsed,
        bcn: this.partBCNESN,
        bin: this.destBinLPN,
        lpn: this.destinationLPN,
        qty: this.quantity
      };
      let showMessage = false;
      let data = await materialMovementService.fieldsValidTransSelUnits("post", obj, showMessage);
      if (data !== null && data !== undefined) {
        if (data.message !== "NA") {
          this.disableQuantity = true;
          this.disableTransferUnitSubmit = !data[0].enable_submit;
          this.availableQty = data[0].avail_qty;
          this.showTransferUnitSubmit = data[0]?.enable_submit;
        }
      }
    },
    //Submit in Transfer Select Units
    submitTransferSelectUnits() {
      if (this.$refs.transferSelectUnitsForm.validate()) {
        this.inTransitLPN = this.lpnUsed;
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let obj = {
          user_id: parseInt(this.userId),
          from_lpn: this.lpnUsed,
          bcn: this.partBCNESN,
          to_bin: this.destBinLPN,
          to_lpn: this.destinationLPN,
          qty: parseInt(this.quantity),
          reason_key: 0,
          testbit: 0,
        };
        this.axios
          .post("mm/putaway_lpn_transfer_unit", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let data = JSON.parse(response.data.body.message);
              this.disableTransferEntireLPN = !data?.Result1[0]?.enable_entire;
              this.disableTransferSelectUnits = !data?.Result1[0]?.enable_select;
              this.lpnUsed = data?.Result1[0]?.lpn;
              this.inTransitLPN = this.lpnUsed;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.getBreadCrumbLPN();
              if (this.lpnNum !== "" && this.lpnNum !== undefined) {
                let transferSelectUnits = true;
                this.formsReset(transferSelectUnits);
              } else {
                this.formsReset();
              }
              this.onPageLoadGetButtons();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //View LPN button click
    async viewLPN() {
      if (this.showTransferEntireLPN) {
        this.paramTransferred = 0;
      } else {
        this.paramTransferred = 1;
      }
      let obj = {
        lpn: this.inTransitLPN,
        user_id: parseInt(this.userId),
        transferred: parseInt(this.paramTransferred),
      };
      let showMessage = false;
      let lpnContents = await materialMovementService.getLPNContents("post", obj, showMessage);
      if (lpnContents !== null && lpnContents !== undefined) {
        if (lpnContents.message !== "NA") {
          if (this.paramTransferred == 0) {
            this.lpnDetailsItems = lpnContents;
          } else {
            this.lpnDetailsItems = lpnContents.Result;
            this.transferredItems = lpnContents.Transferred;
            if (this.transferredItems.length == 0) {
              this.showTransferred = false;
            } else {
              this.showTransferred = true;
            }
          }
          this.lpnDetailsDialog = true;
          if (this.showTransferEntireLPN) {
            this.$refs.transferEntireLPNForm.resetValidation();
          } else if (this.showTransferSelectUnits) {
            this.$refs.transferSelectUnitsForm.resetValidation();
          }
        }
      }
    },
    //Close button on popup
    closeDialog() {
      this.lpnDetailsDialog = false;
      this.lpnDetailsItems = [];
    },
    //Reset Forms
    formsReset(transferSelectUnits) {
      this.lpnDestination = "";
      this.showDivider = false;
      this.showViewReset = false;
      this.disableTransEntireSubmit = true;
      this.disableLPNDestination = false;
      this.paramTransferred = 0;
      this.partBCNESN = "";
      this.disablePartBCNESN = false;
      this.destBinLPN = "";
      this.disableDestBinLPN = true;
      this.destinationLPN = "";
      this.disableDestinationLPN = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableTransferUnitSubmit = true;
      this.showDivider = false;
      this.showViewReset = false;
      this.availDetItems = [];
      this.showTransferred = false;
      this.showDestBinLPN = false;
      this.showDestinationLPN = false;
      this.showQuantity = false;
      this.showTransferUnitSubmit = false;
      if (this.showTransferEntireLPN) {
        this.$refs.transferEntireLPNForm.resetValidation();
        this.showTransferEntireLPN = false;
      } else if (this.showTransferSelectUnits) {
        this.$refs.transferSelectUnitsForm.resetValidation();
        this.showTransferSelectUnits = false;
        if (transferSelectUnits) {
          this.showTransferSelectUnits = true;
          this.showDivider = true;
          this.showViewReset = true;
        } else {
          this.showTransferSelectUnits = false;
        }
      }
    },
    //Cancel button click event
    onClickCancel() {
      this.partBCNESN = "";
      this.disablePartBCNESN = false;
      this.destBinLPN = "";
      this.disableDestBinLPN = true;
      this.destinationLPN = "";
      this.disableDestinationLPN = true;
      this.quantity = "";
      this.disableQuantity = true;
      this.disableTransferUnitSubmit = true;
      this.showDivider = true;
      this.showViewReset = true;
      this.showTransferred = false;
      this.showDestBinLPN = false;
      this.showDestinationLPN = false;
      this.showQuantity = false;
      this.showTransferUnitSubmit = false;
      this.availDetItems = [];
      this.$refs.transferSelectUnitsForm.resetValidation();
      this.onPageLoadGetButtons();
    },
    //to check if its a number with hyphen of not
    lpnNumFormat(event, num) {
      this.destinationLPN = Utility.formatLPNNumber(event, num);
    },
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
